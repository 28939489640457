/* global google */
import { updateSliderCounter } from './functions';

function initMap($el) {
  const mapStyles = [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e9e9e9',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dedede',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: '#333333',
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f2f2f2',
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
  ];

  // Find marker elements within map.
  const $markers = $el.find('.marker');

  // Create gerenic map.
  const mapArgs = {
    zoom: $el.data('zoom') || 16,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: mapStyles,
    // disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    },
  };
  const map = new google.maps.Map($el[0], mapArgs);

  // Add markers.
  map.markers = [];
  $markers.each(function () {
    initMarker($(this), map);
  });

  // Center map based on markers.
  centerMap(map);

  // Return map instance.
  return map;
}

function initMarker($marker, map) {
  // Get position from marker.
  const lat = $marker.data('lat');
  const lng = $marker.data('lng');
  const slideIdx = $marker.data('marker-id');
  const latLng = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  const mapDiv = $(map.getDiv());
  const icon = mapDiv.attr('data-marker');
  const iconActive = mapDiv.attr('data-marker-active');

  const markerOptions = {
    position: latLng,
    map: map,
    icon: icon,
    slideId: slideIdx,
    zIndex: 1,
  };

  if (slideIdx === 0) {
    markerOptions.icon = iconActive;
    markerOptions.zIndex = 2;
  }

  // Create marker instance.
  const marker = new google.maps.Marker(markerOptions);

  if (mapDiv.next('.location-map-slider-wrapper').find('.location-map-slider').length) {
    google.maps.event.addListener(marker, 'click', function () {
      mapDiv
        .next('.location-map-slider-wrapper')
        .find('.location-map-slider')
        .slick('slickGoTo', this.slideId);
      map.markers.forEach((marker) => {
        marker.setIcon(icon);
        marker.setZIndex(1);
      });
      marker.setIcon(iconActive);
      marker.setZIndex(2);
    });
  }

  // Append to reference for later use.
  map.markers.push(marker);
}

// function centerMap(map) {
//   const markers = map.markers;
//   if (markers.length) {
//     const firstMarker = markers[0].getPosition();
//     const firstMarkerPos = {
//       lat: firstMarker.lat(),
//       lng: firstMarker.lng() + 0.05,
//     };
//     map.setCenter(firstMarkerPos);
//   }
// }

function centerMap(map) {
  // vars
  const bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  $.each(map.markers, function (i, marker) {
    const latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
    bounds.extend(latlng);
  });

  // only 1 marker?
  if (map.markers.length === 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
  } else {
    // fit to bounds
    map.fitBounds(bounds);
  }
}

setTimeout(function () {
  $('.acf-map').each((idx, item) => {
    const $this = $(item);
    const map = initMap($this);

    if ($this.next('.location-map-slider-wrapper').find('.location-map-slider').length) {
      const controls = $this
        .next('.location-map-slider-wrapper')
        .find('.slider-pagination');

      $this
        .next('.location-map-slider-wrapper')
        .find('.location-map-slider')
        .slick({
          slidesToShow: 1,
          rows: 0,
          draggable: false,
          arrows: true,
          prevArrow: controls.find('.slick-prev'),
          nextArrow: controls.find('.slick-next'),
        })
        .on('beforeChange', function (e, slick, currentSlide, nextSlide) {
          const nextMarker = map.markers[nextSlide];
          const nextPosition = nextMarker.getPosition();
          const nextLatLng = {
            lat: nextPosition.lat(),
            lng: nextPosition.lng() + 0.05,
          };
          map.panTo(nextLatLng);
          map.markers.forEach((marker) => {
            marker.setIcon($this.attr('data-marker'));
            marker.setZIndex(1);
          });
          nextMarker.setIcon($this.attr('data-marker-active'));
          nextMarker.setZIndex(2);
          updateSliderCounter(e, slick, currentSlide, nextSlide);
        });
    }
  });
}, 300);
