import Foundation from './foundation';

export function initSliderOnMobile(selector, breakpoint = 'large') {
  $(window).on('load changed.zf.mediaquery', function () {
    $(selector).each(function (idx, item) {
      let isSlider = $(item).hasClass('slick-initialized');

      if (Foundation.MediaQuery.is(breakpoint)) {
        if (isSlider) {
          $(item).slick('unslick');
        }
      } else if (!isSlider) {
        let slider = $(item).slick({
          infinite: false,
          slidesToShow: 2,
          speed: 600,
          arrows: true,
          prevArrow: $(item).closest('section').find('.slider_arrow_prev'),
          nextArrow: $(item).closest('section').find('.slider_arrow_next'),
          responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });

        sliderFractions(slider);
      }
    });
  });
}

function sliderFractions(slider) {
  const curFractionSlide = $(slider)
    .closest('section')
    .find('.slider_fractions .current_slide');

  const buttonNextSlideElement = $(slider)
    .closest('section')
    .find('.slider_controls .slider_arrow_next');

  //display counter
  slider.on('afterChange', function (event, slick, currentSlide) {
    const thisSlide = currentSlide + 1;
    if (thisSlide >= 10) {
      curFractionSlide.text(thisSlide);
    } else {
      curFractionSlide.text('0' + thisSlide);
    }

    if (buttonNextSlideElement.hasClass('slick-disabled')) {
      buttonNextSlideElement.addClass('focus');
    } else {
      buttonNextSlideElement.removeClass('focus');
    }
  });
}

export function updateSliderCounter(event, slick, currentSlide, nextSlide) {
  const parentSection = slick.$slider.closest('section');
  const counterWrap = parentSection.find('.slider__counter');
  const current = nextSlide + 1;
  const currentFormatted = current.toString().padStart(2, '0');
  counterWrap.find('.current').text(currentFormatted);
}
